<template>
  <div class="bjcont bjcont-deep tableList">
    <div class="quotationSheet table" ref="container">
      <el-table :span-method="objectSpanMethod" :data="QuotationSheet" border style="width: 100%">
        <el-table-column prop="SerialNumber" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="detail" label="明细项" width="150"></el-table-column>
        <el-table-column label="报价">
          <el-table-column prop="kpi" label="指标" width="120"></el-table-column>
          <el-table-column :key="index" v-for="(item,index) in supplierList" :prop="item.code" :label="item.label"></el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <div class="quotationSummary" :style="{width: setWidth}">
      <el-table size=small :data="QuotationSummary" style="width: 100%" border>
        <el-table-column prop="fieldName" width="320"></el-table-column>
        <el-table-column :key="index" v-for="(item,index) in supplierList" :prop="item.code">
          <template #default="scope">
            
            <!-- <div v-if="(scope.$index+1) % 2 !== 0">{{scope.row[item.code]}}</div>
            <div v-if="(scope.$index+1) % 2 === 0">{{scope.row[item.code]}}</div>-->
            <div v-if="scope.$index ===  2" class="is-disabled">
                <el-upload   :on-preview="handlePreviewFile" :file-list="scope.row[item.code]"></el-upload>
            </div> 
            <div v-else>{{scope.row[item.code]}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import { MIME } from '@/assets/js/mime.js'
// import { exportForm } from '@/assets/js/http.js'
import { baseURL} from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  name: 'QuotationInformation',
  props: ['formData'],
  data () {
    return {
      setWidth: '',
      quoteList: [],
      fileList:[],
      mergeNum: 1,
      supplierList: [],
      QuotationSheet: [],
      QuotationSummary: [
        {
          fieldName: '价格有效期'
        },
        {
          fieldName: '报价说明'
        }
        ,
        {
          fieldName: '报价附件'
        }
      ]
    }
  },
  watch: {
    formData: function (val) {
      this.quoteList = val.quoteList
      if (val.quoteList.length > 0) {
        var quoteList = val.quoteList
        var mergeNum = Object.keys(quoteList[0].material[0]).length - 1
        this.mergeNum = mergeNum
        var tempSupplierList = []
        var tempQuotationList = []
        var materialNum = val.quoteList[0].material.length
        for (var t = 0; t < materialNum; t++) {
          var m = 0
          // eslint-disable-next-line no-unused-vars
          for (var c in val.quoteList[0].material[0]) {
            if (m !== 0) {
              tempQuotationList.push({})
            }
            m = 1
          }
        }
        for (var i in val.quoteList) {
          var tempSupplier = {}
          var priceTime = val.quoteList[i].priceEndTime ? dayjs(val.quoteList[i].priceEndTime).format('YYYY-MM-DD HH:mm:ss') : ''
          tempSupplier.code = i + ''
          tempSupplier.label = val.quoteList[i].supplier
          tempSupplierList.push(tempSupplier)
          this.QuotationSummary[0][i + ''] = priceTime
          this.QuotationSummary[1][i + ''] = val.quoteList[i].quoteDesc
          this.QuotationSummary[2][i + ''] = val.quoteList[i].fileList
          var g = 0
          for (var s in val.quoteList[i].material) {
            var l = 0
            var detailName = ''
            for (var v in val.quoteList[i].material[s]) {
              if (l !== 0) {
                tempQuotationList[g].SerialNumber = parseInt(s) + 1
                tempQuotationList[g].detail = detailName
                tempQuotationList[g].kpi = v
                tempQuotationList[g][i + ''] = val.quoteList[i].material[s][v].dynamicValue
                g++
              } else {
                detailName = val.quoteList[i].material[s][v].dynamicValue
              }
              l++
            }
          }
        }
        this.supplierList = tempSupplierList
        this.QuotationSheet = tempQuotationList
      }
    }
  },
  mounted () {
    if (this.formData && Object.keys(this.formData).length > 0) {
      this.quoteList = this.formData.quoteList
      if (this.formData.quoteList.length > 0) {
        var quoteList = this.formData.quoteList
        var mergeNum = Object.keys(quoteList[0].material[0]).length - 1
        this.mergeNum = mergeNum
        var tempSupplierList = []
        var tempQuotationList = []
        var materialNum = this.formData.quoteList[0].material.length
        for (var t = 0; t < materialNum; t++) {
          var m = 0
          // eslint-disable-next-line no-unused-vars
          for (var c in this.formData.quoteList[0].material[0]) {
            if (m !== 0) {
              tempQuotationList.push({})
            }
            m = 1
          }
        }
        for (var i in this.formData.quoteList) {
          var tempSupplier = {}
          var priceTime = dayjs(this.formData.quoteList[i].priceEndTime).format('YYYY-MM-DD HH:mm:ss')
          tempSupplier.code = i + ''
          tempSupplier.label = this.formData.quoteList[i].supplier
          tempSupplierList.push(tempSupplier)
          this.QuotationSummary[0][i + ''] = priceTime
          this.QuotationSummary[1][i + ''] = this.formData.quoteList[i].quoteDesc
          this.QuotationSummary[2][i + ''] = this.formData.quoteList[i].fileList
          var g = 0
          for (var s in this.formData.quoteList[i].material) {
            var l = 0
            var detailName = ''
            for (var v in this.formData.quoteList[i].material[s]) {
              if (l !== 0) {
                tempQuotationList[g].SerialNumber = parseInt(s) + 1
                tempQuotationList[g].detail = detailName
                tempQuotationList[g].kpi = v
                tempQuotationList[g][i + ''] = this.formData.quoteList[i].material[s][v].dynamicValue
                g++
              } else {
                detailName = this.formData.quoteList[i].material[s][v].dynamicValue
              }
              l++
            }
          }
        }
        this.supplierList = tempSupplierList
        this.QuotationSheet = tempQuotationList
      }
    }
    this.GetDomInfo()
  },
  created () {
    window.addEventListener('resize', this.GetDomInfo)
  },
  methods: {
    handlePreviewFile (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id + '?type=1')
    },
    bidDocClick: function (data) {
      this.handlePreview(data)
    },
    GetDomInfo () {
      this.$nextTick(() => {
        const domWidth = document.querySelector('.quotationSheet .el-table').clientWidth
        this.setWidth = domWidth + 'px'
      })
    },
    // 合并第一列和第二列单元格
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        if (rowIndex % this.mergeNum === 0) {
          return {
            rowspan: this.mergeNum,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
:deep(.is-disabled){
    .el-upload-list__item:hover .el-icon-close{
        display: none!important;
    }
    .el-icon-close-tip{
        display: none!important;
    }
}
:deep(.el-upload) {
    display: none;
}
  .bjcont-deep{
    :deep(.el-table td), :deep(.el-table th){
      text-align: center;
    }
    :deep(.el-table thead.is-group th){
      background: none;
    }
    :deep(.el-table--small){
      font-size: 14px;
    }
  }

  .bjcont{
    position: relative;
    overflow: hidden;
  }
  .quotationSheet{
    z-index: 1;
    position: relative;
    max-height: 430px;
    overflow: auto;
  }
  .quotationSummary{
    width: 100%;
    :deep(.el-table__header-wrapper){
      display: none;
    }
  }
  .tableList{
    padding: 0;
    :deep(.table){
      .el-table__header-wrapper .el-table__header th {
        // background-color: #fafafa;
    }
}
  }
</style>
